<template>
  <div>
    <!--breadcrumbs-->
    <v-container>
      <v-breadcrumbs class="pa-1 pt-3 breadcrumb" :items="breadcrumbs">
        <template v-slot:divider>
          <v-icon>mdi-chevron-left</v-icon>
        </template>
      </v-breadcrumbs>
      <h3 class="mt-0 font-weight-medium">مزامنة حسابك مع هكتار</h3>
      <h5 class="text-light font-weight-medium">
        اختر العقارت التي تريد جلبها من هكتار، أو اضغط على اختر الكل.
      </h5>
      <div class="mt-0 mt-lg-8 d-flex justify-space-between align-center">
        <div class="d-flex justify-start align-center">
          <v-checkbox
            class="ml-1 mt-6"
            color="rib"
            v-model="checkbox"
            size="small"
          ></v-checkbox>
          <h4>
            عقاراتك في هكتار<span class="mx-2 h5 text-light font-weight-medium"
              >١٠٧٣ عقار</span
            >
          </h4>
        </div>
      </div>
    </v-container>
    <!--  No synced-->
    <v-container
      v-if="syncedProperties.length === 0"
      fluid
      class="main-container"
    >
      <div class="sync-container">
        <div class="sync-message">
          <img :src="sync" loading="lazy" />
          <h3>لا توجد عقارات لديك ف هكتار!</h3>
          <v-btn
            rounded
            x-large
            color="primary"
            :loading="isLoading"
            @click="syncNow"
          >
            <v-icon class="ml-2">mdi-plus</v-icon>
            <span class="tf">اضف عقاراتك الآن </span>
          </v-btn>
        </div>
      </div>
    </v-container>
    <!--synced-->
    <synced v-else />
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import '@/styles/material-dashboard/_sync.scss'
import synced from '../components/properties/synced'

export default {
  components: { synced },
  data: () => {
    return {
      syncDialog: false,
      addLoading: false,
      isLoading: false,
      singleSelect: false,
      checkbox: false,
      breadcrumbs: [
        {
          text: 'قائمة العقارات',
          disabled: false,
          exact: true,
          link: true,
          to: { name: 'properties', params: { addNewProperty: 0 } }
        },
        { text: 'مزامنة هكتار', disabled: true }
      ],
      selected: [],
      headers: [
        {
          text: 'نوع العقار',
          align: 'start',
          sortable: false,
          value: 'name'
        },
        { text: 'تاريخ الإضافة', value: 'date' },
        { text: 'رقم العقار', value: 'num' },
        { text: 'نوع العقار', value: 'type' },
        { text: 'نوع العملية', value: 'operationType' }
      ],
      syncedProperties: [
        {
          name: 'شقة للإيجار',
          date: '١٢ مارس ٢٠٢٠',
          num: 15,
          type: 'شقة',
          operationType: 'تمت'
        },
        {
          name: 'شقة للإيجار',
          date: '١٢ مارس ٢٠٢٠',
          num: 6,
          type: 'شقة',
          operationType: 'تمت'
        },
        {
          name: 'شقة للإيجار',
          date: '١٢ مارس ٢٠٢٠',
          num: 12,
          type: 'شقة',
          operationType: 'تمت'
        },
        {
          name: 'شقة للإيجار',
          date: '١٢ مارس ٢٠٢٠',
          num: 1,
          type: 'شقة',
          operationType: 'تمت'
        },
        {
          name: 'شقة للإيجار',
          date: '١٢ مارس ٢٠٢٠',
          num: 2,
          type: 'شقة',
          operationType: 'تمت'
        }
      ]
    }
  },
  methods: {
    ...mapMutations('appState', ['addNotification', 'toggleDrawer']),
    syncNow() {
      this.isLoading = true

      setTimeout(() => {
        this.syncDialog = true
        this.isLoading = false
      }, 1000)
    },
    addProperties() {
      this.addLoading = true
      setTimeout(() => {
        this.addLoading = false
        this.syncDialog = false
        this.addNotification({
          text: 'تم إضافة عقاراتك لهكتار بلس بنجاح، شكراً لك!',
          color: 'success'
        })
        setTimeout(() => {
          this.$router.push({
            name: 'properties',
            params: { addNewProperty: 0 }
          })
        }, 1000)
      }, 2000)
    }
  },
  computed: {
    ...mapState('accountState', ['configs'])
  },
  metaInfo() {
    return {
      title: 'مزامنة مع هكتار'
    }
  }
}
</script>
